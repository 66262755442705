<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <AMonthPicker
                    class="mr-2 mt-2"
                    v-model:value="state.params.month"
                    placeholder="Pilih Bulan"
                    style="width:300px"
                    format="MMMM YYYY"/>
                <FilterDistributor
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.distributor"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"
                    v-model:kabupaten="state.params.kabupaten"/>
                <FilterBrand
                    class="mr-2"
                    v-model:value="state.params.brand"/>
                <FilterRegional
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.region"/>
            </div>
            <div class="col-md-12 mt-2">
                <FilterArea
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.area"/>
                <!-- <FilterKabupatenKota
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.kabupaten"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"/> -->
                <AInputSearch
                    class="mr-2"
                    v-model:value="state.params.q"
                    placeholder="Cari..."
                    style="width:300px"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="btnFetchData()"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end mt-5">
            <div class="col-lg-2 col-md-12 text-center" style="display:flex;flex-direction:column;">
                <div class="card">
                    <div class="card-body">
                        <ASkeleton
                            active
                            :paragraph="{ rows: 2 }"
                            :loading="state.loadingTotalUniverse">
                            <h1>{{ state.total_universe }}</h1>
                            <strong>Total Toko Universe</strong>
                        </ASkeleton>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <ASkeleton
                            active
                            :paragraph="{ rows: 2 }"
                            :loading="state.loadingTotalActive">
                            <h1>{{ state.total_active }}</h1>
                            <strong>Total Toko Aktif</strong>
                        </ASkeleton>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12 mt-auto">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Diagram Toko Aktif</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <DownloadExcel
                                    :url="state.endpoint.diagram_toko"
                                    :params="state.params"
                                    namefile="Diagram-Toko-Aktif"
                                    @errors="errorMessage" />
                            </div>
                        </div>
                        <EChartCustom
                            :series="state.diagram_toko.series"
                            :labelx="state.diagram_toko.labelx"
                            :legend="state.diagram_toko.legend"
                            :loading="state.diagram_toko.loading"/>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12 text-right mt-auto">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Toko Area Aktif</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <DownloadExcel
                                    :url="state.endpoint.area_toko"
                                    :params="state.params"
                                    namefile="Toko-Area-Aktif"
                                    @errors="errorMessage" />
                            </div>
                        </div>
                        <EChartCustom
                            :series="state.area_toko.series"
                            :legend="state.area_toko.legend"
                            :grid="state.area_toko.grid"
                            :labelx="state.area_toko.labelx"
                            :labely="state.area_toko.labely"
                            :loading="state.area_toko.loading"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-end">
            <div class="col-lg-6 col-md-12 mt-auto">
            </div>
            <div class="col-lg-6 col-md-12 text-right mt-auto">
                <DownloadExcel
                    :url="state.endpoint.current"
                    :params="state.params"
                    namefile="Laporan-Toko-Aktif-(CA)-(Admin)"
                    @errors="errorMessage" />
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch, onBeforeMount } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
// import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterBrand from '@/components/filter/FilterBrand'
import EChartCustom from '@/components/charts/EChartCustom'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import { debounce, pickBy } from 'lodash'
import moment from 'moment'

export default defineComponent({
    components: {
        // FilterKabupatenKota,
        FilterDistributor,
        FilterRegional,
        FilterArea,
        FilterBrand,
        EChartCustom,
        DownloadExcel,
    },
    setup() {
        const errorMessage = ref()

        const state = reactive({
            columns: [
                {
                    title: 'Tanggal',
                    dataIndex: 'Tanggal',
                },
                {
                    title: 'ID Toko',
                    dataIndex: 'Toko_ID',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'Nama_Toko',
                },
                {
                    title: 'Alamat Toko',
                    dataIndex: 'Alamat_Toko',
                },
                {
                    title: 'Kabupaten Toko',
                    dataIndex: 'Kabupaten_Toko',
                },
                {
                    title: 'Provinsi Toko',
                    dataIndex: 'Provinsi_Toko',
                },
                {
                    title: 'Regional Toko',
                    dataIndex: 'Toko_Regional',
                },
                {
                    title: 'Area Toko',
                    dataIndex: 'Toko_Area',
                },

                {
                    title: 'Brands',
                    dataIndex: 'Brands',
                },
                {
                    title: 'Kode Produk',
                    dataIndex: 'Kode_Produk',
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'Nama_Produk',
                },
                {
                    title: 'Harga',
                    dataIndex: 'Harga',
                },
                {
                    title: 'QTY Zak',
                    dataIndex: 'Volume_ZAK',
                },
                {
                    title: 'QTY Ton',
                    dataIndex: 'Volume_TON',
                },
                {
                    title: 'No Transaksi',
                    dataIndex: 'Kode_Transaksi',
                },
                {
                    title: 'Kode Gudang',
                    dataIndex: 'Warehouse_Code',
                },
                {
                    title: 'Nama Gudang',
                    dataIndex: 'Warehouse_Name',
                },
                {
                    title: 'Regional Gudang',
                    dataIndex: 'Warehouse_Regional',
                },
                {
                    title: 'Provinsi Gudang',
                    dataIndex: 'Warehouse_Provinsi',
                },
                {
                    title: 'Area Gudang',
                    dataIndex: 'Warehouse_Area',
                },
                {
                    title: 'Kabupaten Gudang',
                    dataIndex: 'Warehouse_Kota_Kabupaten',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'Distributor_ID',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'Distributor_Name',
                },
                {
                    title: 'Tipe Pelanggan',
                    dataIndex: 'Customer_Type',
                },
                {
                    title: 'Cluster',
                    dataIndex: 'Cluster',
                },
                {
                    title: 'SSM',
                    dataIndex: 'SSM',
                },
                {
                    title: 'ASM',
                    dataIndex: 'SM',
                },
                {
                    title: 'TSO',
                    dataIndex: 'AM',
                },
            ],
            endpoint: {
                current: '/api/report/laporan-ca-admin/raw-data',
                diagram_toko: `/api/report/laporan-ca-admin/diagram-toko-aktif-and-universe`,
                area_toko: `/api/report/laporan-ca-admin/diagram-toko-aktif-by-area`,
                total_active: `/api/report/laporan-ca-admin/total-aktif`,
                total_universe: `/api/report/laporan-ca-admin/total-universe`,
            },
            data: [],
            graph: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            loadingTotalActive: false,
            loadingTotalUniverse: false,
            params: {
                q: '',
                region: [],
                distributor: [],
                area: [],
                kabupaten: [],
                brand: [],
                month: moment(new Date()).startOf('month').format('YYYY-MM'),
                page: 1,
                "per-page": 10,
            },
            total_active: 0,
            total_universe: 0,
            diagram_toko: {
                loading: false,
                series: [],
                labelx: [],
                legend: {
                    type: 'scroll',
                    bottom: 0,
                    padding: [0, 0, 0, 0],
                },
            },
            area_toko: {
                loading: false,
                series: [],
                labely: [],
                labelx: [{
                    type: 'value',
                    name: 'Total Volume Area',
                    nameLocation: 'middle',
                    nameGap: 30,
                    axisLabel: {
                        formatter: function (a) {
                            const formatter = Intl.NumberFormat('id-ID', { notation: 'compact' })
                            return formatter.format(a)
                        },
                    },
                }],
                legend: {
                    type: 'scroll',
                    bottom: 0,
                    padding: [0, 0, 0, 0],
                },
                grid: {
                    containLabel: true,
                    left: 30,
                    right: 20,
                    bottom: 20,
                },
            },
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize
            
            btnFetchData()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.month) {
                params.month = moment(state.params.month).format('YYYY-MM')
            }

            return pickBy(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const fetchDataGraph = () => {
            state.diagram_toko.loading = true
            apiClient
                .get(state.endpoint.diagram_toko, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    if (data.items) {
                        state.graph = data.items

                        state.diagram_toko.labelx = [{
                            type: 'category',
                            axisLabel: {
                                interval: 0,
                            },
                            data: data.items.map(item => item.toko_regional),
                        }]

                        state.diagram_toko.series = [
                            {
                                name: 'Toko Universe',
                                type: 'bar',
                                emphasis: {
                                    focus: 'series',
                                },
                                barGap: 0,
                                color: "#8879B0",
                                data: data.items.map(item => item.ID_Toko_Universe),
                            },
                            {
                                name: 'Toko Aktif',
                                type: 'bar',
                                emphasis: {
                                    focus: 'series',
                                },
                                color: '#66BFBF',
                                data: data.items.map(item => item.id_toko_active),
                            },
                        ]
                    }
                })
                .catch(error => console.error(error))
                .finally(() => {
                    state.diagram_toko.loading = false
                })
        }

        const fetchDataArea = () => {
            state.area_toko.loading = true
            apiClient
                .get(state.endpoint.area_toko, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    if (data.items) {
                        state.area_toko.labely = [{
                            type: 'category',
                            axisLabel: {
                                show: false,
                            },
                            data: data.items.map(item => item.toko_area),
                            inverse: true,
                            name: 'Nama Area',
                            nameLocation: 'middle',
                        }]

                        state.area_toko.series = [{
                            type: 'bar',
                            data: data.items.map(item => item.id_toko_active),
                            showBackground: true,
                            label: {
                                show: true,
                                formatter: '{b}',
                                position: 'insideLeft',
                            },
                        }]
                    }
                })
                .catch(error => console.error(error))
                .finally(() => {
                    state.area_toko.loading = false
                })
        }

        const fetchDataTotalActive = () => {
            state.loadingTotalActive = true
            apiClient
                .get(state.endpoint.total_active, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    if (data.items) {
                        data.items.forEach((item, key) => {
                            state.total_active = item["ID_Toko_Active"]
                        });
                    }
                })
                .catch(error => console.error(error))
                .finally(() => {
                    state.loadingTotalActive = false
                })
        }

        const fetchDataTotalUniverse = () => {
            state.loadingTotalUniverse = true
            apiClient
                .get(state.endpoint.total_universe, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    if (data.items) {
                        data.items.forEach((item, key) => {
                            state.total_universe = item["ID_Toko_Universe"]
                        });
                    }
                })
                .catch(error => console.error(error))
                .finally(() => {
                    state.loadingTotalUniverse = false
                })
        }

        const btnFetchData = () => {
            fetchDataList()
            fetchDataGraph()
            fetchDataArea()
            fetchDataTotalActive()
            fetchDataTotalUniverse()
        }

        // handle vue
        onMounted(() => {
            btnFetchData()
        })

        return {
            btnFetchData,
            state,
            handleTableChange,
            errorMessage,
        }
    },
})
</script>

<style lang="scss" scoped>
.card {
    height: 100%;
    .card-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
</style>